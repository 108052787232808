<template>
  <div>
    <el-form :model="ruleForm" label-width="90px" class="demo-ruleForm" size="small"
             label-position="top" ref="validateFrom" :rules="rules">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <p style="display:flex;background-color: #d1d1d1;padding:5px;margin-bottom:5px;margin-top:10px;">
            <span style="font-weight: 700;color:#4a4a4a;">{{ $t('安全设置') }}</span>
          </p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('最大通话时长秒')" prop="maxCallDuration">
            <el-input-number v-model="ruleForm.maxCallDuration" maxlength="4" :step="2" :min="0" :max="9999"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item class="label" label="" prop="maxRegTimes">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('十秒内最大注册尝试次数') }}
                <i class="fa fa-list" style="margin-left: 3px;cursor: pointer;color:#409eff"
                   @click="onOpenLockData"></i>
              </span>
              <template #content>{{ $t('查看锁定地址') }}</template>
            </el-tooltip>
            <el-input-number v-model="ruleForm.maxRegTimes" maxlength="3" :step="2" :min="0" :max="999"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item :label="$t('锁定时长秒')" prop="lockDuration">
            <el-input-number v-model="ruleForm.lockDuration" maxlength="4" :step="2" :min="0" :max="9999"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <p style="display:flex;background-color: #d1d1d1;padding:5px;margin-bottom:5px;">
            <span style="font-weight: 700;color:#4a4a4a;">{{ $t('性能设置') }}</span>
            <span style="margin-left:auto;color:#f63d3d;"><i class="fa fa-info-circle"></i>
            {{ $t('修改当前设置会重启语音服务并中断当前所有通话') }}
            </span>
          </p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <el-form-item :label="$t('总并发')" prop="concurrent">
            <el-input-number v-model="ruleForm.concurrent" maxlength="3" :step="2" :min="0" :max="999"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <el-form-item label="" prop="caps">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('总caps') }}
                <el-icon size="small" color="#409eff">
                  <info-filled/>
                </el-icon>
              </span>
              <template #content>
                {{ $t('每秒建立呼叫数量上限') }}
              </template>
            </el-tooltip>
            <el-input-number v-model="ruleForm.caps" maxlength="3" :step="2" :min="0" :max="999"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <p style="display:flex;background-color:#d1d1d1;padding:5px;margin-bottom:5px;margin-top:10px;">
            <span style="font-weight: 700;color:#4a4a4a;">{{ $t('网络设置') }}</span>
            <span style="margin-left:auto;color:#f63d3d;"><i class="fa fa-info-circle"></i>
            {{ $t('修改当前设置会重启语音服务并中断当前所有通话') }}
            </span>
          </p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item label="" prop="intIp">
            <span>{{ $t('SIP协议内网IP') }}
                <el-switch
                    v-model="ruleForm.intIpOpen"
                    class="switch"
                    inline-prompt
                    active-value="1"
                    inactive-value="0"
                    style="--el-switch-off-color: #8a8b8a;"
                    :active-text="$t('手动指定')"
                    :inactive-text="$t('自动获取')"
                />
              </span>
            <el-input :disabled="ruleForm.intIpOpen==0" v-model="ruleForm.intIp" maxlength="16"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item label="" prop="extIp">
            <span>{{ $t('SIP协议公网IP') }}
              <el-switch
                  v-model="ruleForm.extIpOpen"
                  class="switch"
                  inline-prompt
                  active-value="1"
                  inactive-value="0"
                  style="--el-switch-off-color: #8a8b8a;"
                  :active-text="$t('手动指定')"
                  :inactive-text="$t('自动获取')"
              />
              </span>
            <el-input :disabled="ruleForm.extIpOpen==0" v-model="ruleForm.extIp" maxlength="16"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <el-form-item :label="$t('SIP认证端口')" prop="sipIport">
            <el-input v-model="ruleForm.sipIport" maxlength="16"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <el-form-item :label="$t('SIP对接端口')" prop="sipEport">
            <el-input v-model="ruleForm.sipEport" maxlength="16"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item label="">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('RTP端口范围') }}
                <el-icon size="small" color="#409eff">
                  <info-filled/>
                </el-icon>
              </span>
              <template #content>
                1 - 65535
              </template>
            </el-tooltip>
            <div class="input-number-range">
              <div class="flex">
                <div class="from">
                  <el-input
                      ref="input_from"
                      v-model="ruleForm.rtpSport"
                      :placeholder="$t('起始值')"
                      @blur="handleBlurFrom"
                      @focus="handleFocusFrom"
                      @input="handleInputFrom"
                      @change="handleInputChangeFrom"
                  ></el-input>
                </div>
                <div class="center">
                  <span>{{ $t('至') }}</span>
                </div>
                <div class="to">
                  <el-input
                      ref="input_to"
                      v-model="ruleForm.rtpEport"
                      :placeholder="$t('结束值')"
                      @blur="handleBlurTo"
                      @focus="handleFocusTo"
                      @input="handleInputTo"
                      @change="handleInputChangeTo"
                  ></el-input>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-affix position="bottom" :offset="10">
          <el-button :disabled="!canSave" type="primary" @click="submitForm" :loading="!canSave">
            {{ saveTxt }}
          </el-button>
        </el-affix>
      </el-form-item>
      <lockData ref="lockDataRef"/>
    </el-form>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  ref, computed, onMounted,
} from 'vue'
import {useI18n} from "vue-i18n";
import {
  checkFsStatus,
  getConf, updateConf
} from '@/http/system.api.js';
import {
  ElNotification
} from 'element-plus';
import lockData from "./lockData.vue";

export default {
  name: 'confManagement',
  components: {lockData},
  methods: {},
  setup(proxy, context) {
    const {t}   = useI18n()
    const state = reactive({
      timer: '',
      canSave: true,
      saveTxt: t('保存'),
      ruleForm: {
        concurrent: '',
        caps: '',
        maxCallDuration: '',
        maxRegTimes: '',
        lockDuration: '',
        sipEport: '',
        sipIport: '',
        rtpSport: '',
        rtpEport: '',
        intIp: '',
        intIpOpen: '0',
        extIp: '',
        extIpOpen: '0',
      },
    });
    clearInterval(state.timer);
    const validateFrom = ref()
    //校验规则
    const rules        = computed(() => {
      const rule = {}
      return rule;
    });

    onMounted(() => {
      getConf();
    })

    getConf({}).then(res => {
      if (res.code == 200) {
        state.ruleForm.maxCallDuration = res.data.maxCallDuration;
        state.ruleForm.maxRegTimes     = res.data.maxRegTimes;
        state.ruleForm.lockDuration    = res.data.lockDuration;
        state.ruleForm.concurrent      = res.data.concurrent;
        state.ruleForm.caps            = res.data.caps;
        state.ruleForm.sipIport        = res.data.sipIport;
        state.ruleForm.sipEport        = res.data.sipEport;
        state.ruleForm.rtpSport        = res.data.rtpSport;
        state.ruleForm.rtpEport        = res.data.rtpEport;
        state.ruleForm.intIp           = res.data.intIp;
        state.ruleForm.intIpOpen       = res.data.intIpOpen;
        state.ruleForm.extIp           = res.data.extIp;
        state.ruleForm.extIpOpen       = res.data.extIpOpen;

        context.emit('changeTag', res.tag);
      } else {
        ElNotification({
          title: '失败',
          message: res.msg,
          type: 'error',
          duration: 2000,
        });
      }
    });

    function submitForm() {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            concurrent: state.ruleForm.concurrent,
            caps: state.ruleForm.caps,
            maxCallDuration: state.ruleForm.maxCallDuration,
            maxRegTimes: state.ruleForm.maxRegTimes,
            lockDuration: state.ruleForm.lockDuration,
            rtpSport: state.ruleForm.rtpSport,
            rtpEport: state.ruleForm.rtpEport,
            sipIport: state.ruleForm.sipIport,
            sipEport: state.ruleForm.sipEport,
            intIp: state.ruleForm.intIp,
            extIp: state.ruleForm.extIp,
            intIpOpen: state.ruleForm.intIpOpen,
            extIpOpen: state.ruleForm.extIpOpen,
          };
          updateConf(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              if (res.data.restart) {
                //重启中
                state.canSave = false;
                state.saveTxt = t('语音服务重启');
                state.timer   = setInterval(function () {
                  checkFsStatus({}).then(res => {
                    if (res.code == 200) {
                      state.canSave = true;
                      state.saveTxt = t('保存');
                      clearInterval(state.timer);
                      ElNotification({
                        title: t("成功"),
                        message: t("语音服务重启完成"),
                        type: 'success',
                        duration: 2000,
                      });
                    } else {
                      console.log('fs status error', res);
                    }
                  })
                }, 3000);
              }
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    }

    function handleBlurFrom(event) {
      context.emit('blurfrom', event)
    }

    function handleFocusFrom(event) {
      context.emit('focusfrom', event)
    }

    function handleBlurTo(event) {
      context.emit('blurto', event)
    }

    function handleFocusTo(event) {
      context.emit('focusto', event)
    }

    function handleInputFrom(value) {
      context.emit('inputfrom', value)
    }

    function handleInputTo(value) {
      context.emit('inputto', value)
    }

    // from输入框change事件
    function handleInputChangeFrom(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === '') {
        context.emit('input', [null, state.ruleForm.rtpEport])
        context.emit('changefrom', state.ruleForm.rtpSport)
        return
      }

      // 初始化数字精度
      state.ruleForm.rtpSport = parseInt(value);

      // 如果from > to 将from值替换成to
      if (typeof state.ruleForm.rtpEport === 'number') {
        state.ruleForm.rtpSport =
            parseFloat(state.ruleForm.rtpSport) <= parseFloat(state.ruleForm.rtpEport)
                ? state.ruleForm.rtpSport
                : state.ruleForm.rtpEport
      }
      context.emit('input', [state.ruleForm.rtpSport, state.ruleForm.rtpEport])
      context.emit('changefrom', state.ruleForm.rtpSport)
    }

    // to输入框change事件
    function handleInputChangeTo(value) {
      // 如果是非数字空返回null
      if (isNaN(value) || value === '') {
        context.emit('input', [state.ruleForm.rtpSport, null])
        context.emit('changefrom', state.ruleForm.rtpEport)
        return
      }

      // 初始化数字精度
      state.ruleForm.rtpEport = parseInt(value);

      // 如果to < tfrom 将to值替换成from
      if (typeof state.ruleForm.rtpSport === 'number') {
        state.ruleForm.rtpEport =
            parseFloat(state.ruleForm.rtpEport) >= parseFloat(state.ruleForm.rtpSport)
                ? state.ruleForm.rtpEport
                : state.ruleForm.rtpSport
      }
      context.emit('input', [state.ruleForm.rtpSport, state.ruleForm.rtpEport])
      context.emit('changeto', state.ruleForm.rtpEport)
    }

    const lockDataRef    = ref();
    const onOpenLockData = () => {
      lockDataRef.value.openDialog();
    };

    return {
      ...toRefs(state),
      submitForm,
      validateFrom,
      rules,
      lockDataRef,
      handleBlurFrom,
      handleBlurTo,
      handleFocusFrom,
      handleFocusTo,
      handleInputFrom,
      handleInputTo,
      handleInputChangeFrom,
      handleInputChangeTo,
      onOpenLockData,
    }
  }
}
</script>
<style scoped>
.el-affix :deep(.el-affix--fixed) {
  display: flex;
  justify-content: center;
  background: white;
}

.el-button--small {
  margin: 0 10px;
}

.input-number-range {
  background-color: #fff;
  //border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.flex {
  display: flex;
  padding: 0 !important;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .center {
    margin-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.is-disabled {
  background-color: #eef0f6;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>

<style lang="scss">
.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

.switch .el-switch__label--right {
  z-index: 1;
}

.switch .el-switch__label--right span {
  margin-right: 9px;
}

.switch .el-switch__label--left {
  z-index: 1;
}

.switch .el-switch__label--left span {
  margin-left: 22px;
}

.switch .el-switch__label.is-active {
  display: block;
}

.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 90px !important;
}
</style>
