<template>
  <el-card class="!border-none mb10 status-tag" v-show="sysTitle" shadow="never">
    <div style="display: flex">
      <el-alert
          type="error"
          :title="sysTitle"
          :closable="false"
          show-icon
          style="flex-direction: row;font-weight: 500"
      />
    </div>
  </el-card>
  <div>
    <el-card shadow="hover">
      <el-tabs type="border-card">
        <!--        <el-tab-pane label="用户管理"><userManagement /></el-tab-pane>-->
        <!--        <el-tab-pane label="权限管理"><authorityManagement /></el-tab-pane>-->
        <!--        <el-tab-pane :label="$t('授权管理')">-->
        <!--          <systemManagement @changeTag="changeTag"/>-->
        <!--        </el-tab-pane>-->
        <el-tab-pane :label="$t('配置管理')" v-if="!sysTitle">
          <confManagement @changeTag="changeTag"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('存储管理')" v-if="!sysTitle">
          <saveManagement @changeTag="changeTag"/>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>
<script>
// import userManagement from './ConsystemSetup/userManagement';
// import authorityManagement from './ConsystemSetup/authorityManagement';
// import systemManagement from './ConsystemSetup/systemManagement';
import confManagement from './ConsystemSetup/confManagement';
import saveManagement from './ConsystemSetup/saveManagement.vue';
import {reactive, toRefs} from "vue";

export default {
  name: 'systemSetup',
  components: {
    // userManagement,
    // authorityManagement,
    saveManagement,
    confManagement,
  },
  setup() {
    const state     = reactive({
      sysTitle: "",
    });
    const changeTag = (msg) => {
      if (msg) {
        state.sysTitle = msg;
      } else {
        state.sysTitle = '';
      }
    }
    return {
      ...toRefs(state),
      changeTag,
    }
  }
}
</script>
<style lang="scss" scoped>
.el-card {
  --el-card-padding: 0;
}
</style>
