<template>
  <div>
    <el-form :model="ruleForm" label-width="90px" class="demo-ruleForm" size="small"
             label-position="top" ref="validateFrom" :rules="rules">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <p style="display:flex;background-color: #d1d1d1;padding:5px;margin-bottom:5px;margin-top:10px;">
            <span style="font-weight: 700;color:#4a4a4a;">{{ $t('数据设置') }}</span>
            <span style="margin-left:auto;color:#f63d3d;"><i class="fa fa-info-circle"></i>
            {{ $t('每月一号凌晨2点配置自动生效') }}
            </span>
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>
                <i class="fa fa-tasks" style="margin-left: 3px;cursor: pointer;color:#409eff"
                   @click="onOpenTaskData"></i>
              </span>
              <template #content>{{ $t('任务查看') }}</template>
            </el-tooltip>
          </p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item label="" prop="lockDuration">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('话单保存时间月') }}
                <el-icon size="small" color="#409eff">
                  <info-filled/>
                </el-icon>
              </span>
              <template #content>
                1 - 120
              </template>
            </el-tooltip>
            <el-input-number v-model="ruleForm.cdrTime" maxlength="3" :step="1" :min="1" :max="120"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-form-item label="" prop="lockDuration">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('录音保存时间月') }}
                <el-icon size="small" color="#409eff">
                  <info-filled/>
                </el-icon>
              </span>
              <template #content>
                1、{{ $t('时间范围')}}：1 - 120 <br/>
                2、{{ $t('录音保存时间小于等于话单保存时间')}}
              </template>
            </el-tooltip>
            <el-input-number v-model="ruleForm.recordTime" maxlength="3" :step="1" :min="1" :max="120"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <p style="display:flex;background-color: #d1d1d1;padding:5px;margin-bottom:5px;">
            <span style="font-weight: 700;color:#4a4a4a;">{{ $t('日志设置') }}</span>
            <span style="margin-left:auto;color:#f63d3d;"><i class="fa fa-info-circle"></i>
            {{ $t('修改当前设置会重启语音服务并中断当前所有通话') }}
            </span>
          </p>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="" prop="concurrent">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('FreeSWITCH文件个数') }}
                <el-icon size="small" color="#409eff">
                  <info-filled/>
                </el-icon>
              </span>
              <template #content>
                30 - 60
              </template>
            </el-tooltip>
            <el-input-number v-model="ruleForm.fsLogCount" maxlength="3" :step="1" :min="30" :max="60"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="" prop="caps">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('FreeSWITCH单个文件大小') }}
                <el-icon size="small" color="#409eff">
                  <info-filled/>
                </el-icon>
              </span>
              <template #content>
                1 - 200
              </template>
            </el-tooltip>
            <el-input-number v-model="ruleForm.fsLogSize" maxlength="3" :step="1" :min="1" :max="200"/>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="" prop="coreLogCount">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('Core保存天数') }}
                <el-icon size="small" color="#409eff">
                  <info-filled/>
                </el-icon>
              </span>
              <template #content>
                2 - 50
              </template>
            </el-tooltip>
            <el-input-number v-model="ruleForm.coreLogCount" maxlength="3" :step="1" :min="2" :max="50"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="" prop="coreLogSize">
            <el-tooltip class="box-item" effect="dark" placement="bottom">
              <span>{{ $t('Core最大存储空间') }}
                <el-icon size="small" color="#409eff">
                  <info-filled/>
                </el-icon>
              </span>
              <template #content>
                1 - 2048
              </template>
            </el-tooltip>
            <el-input-number v-model="ruleForm.coreLogSize" maxlength="3" :step="1" :min="1" :max="2048"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-affix position="bottom" :offset="10">
          <el-button :disabled="!canSave" type="primary" @click="submitForm" :loading="!canSave">
            {{ saveTxt }}
          </el-button>
        </el-affix>
      </el-form-item>
      <taskData ref="taskDataRef"/>
    </el-form>
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  ref, computed, onMounted,
} from 'vue'
import {useI18n} from "vue-i18n";
import {
  checkFsStatus,
  getStorage, updateStorageConf,
} from '@/http/system.api.js';
import {
  ElNotification
} from 'element-plus';
import taskData from "./taskData.vue";

export default {
  name: 'saveManagement',
  components: {taskData},
  methods: {},
  setup() {
    const {t}   = useI18n()
    const state = reactive({
      timer: '',
      canSave: true,
      saveTxt: t('保存'),
      ruleForm: {
        cdrTime: '',
        recordTime: '',
        fsLogSize: '',
        fsLogCount: '',
        coreLogSize: '',
        coreLogCount: '',
      },
    });
    clearInterval(state.timer);
    const validateFrom = ref()
    //校验规则
    const rules        = computed(() => {
      const rule = {}
      return rule;
    });

    onMounted(() => {
      getStorage();
    })

    getStorage({}).then(res => {
      if (res.code == 200) {
        state.ruleForm.fsLogSize    = res.data.fsLogSize;
        state.ruleForm.fsLogCount   = res.data.fsLogCount;
        state.ruleForm.coreLogSize  = res.data.coreLogSize;
        state.ruleForm.coreLogCount = res.data.coreLogCount;
        state.ruleForm.cdrTime      = res.data.cdrTime;
        state.ruleForm.recordTime   = res.data.recordTime;
      } else {
        ElNotification({
          title: '失败',
          message: res.msg,
          type: 'error',
          duration: 2000,
        });
      }
    });

    function submitForm() {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            cdrTime: state.ruleForm.cdrTime,
            recordTime: state.ruleForm.recordTime,
            fsLogSize: state.ruleForm.fsLogSize,
            fsLogCount: state.ruleForm.fsLogCount,
            coreLogSize: state.ruleForm.coreLogSize,
            coreLogCount: state.ruleForm.coreLogCount,
          };
          updateStorageConf(params).then(res => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("编辑成功"),
                type: 'success',
                duration: 2000,
              });
              if (res.data.restart) {
                //重启中
                state.canSave = false;
                state.saveTxt = t('语音服务重启');
                state.timer   = setInterval(function () {
                  checkFsStatus({}).then(res => {
                    if (res.code == 200) {
                      state.canSave = true;
                      state.saveTxt = t('保存');
                      clearInterval(state.timer);
                      ElNotification({
                        title: t("成功"),
                        message: t("语音服务重启完成"),
                        type: 'success',
                        duration: 2000,
                      });
                    } else {
                      console.log('fs status error', res);
                    }
                  })
                }, 3000);
              }
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    }

    const taskDataRef    = ref();
    const onOpenTaskData = () => {
      taskDataRef.value.openDialog();
    };

    return {
      ...toRefs(state),
      submitForm,
      validateFrom,
      rules,
      taskDataRef,
      onOpenTaskData,
    }
  }
}
</script>
<style scoped>
.el-affix :deep(.el-affix--fixed) {
  display: flex;
  justify-content: center;
  background: white;
}

.el-button--small {
  margin: 0 10px;
}

.input-number-range {
  background-color: #fff;
  //border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.flex {
  display: flex;
  padding: 0 !important;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .center {
    margin-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.is-disabled {
  background-color: #eef0f6;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
