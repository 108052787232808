import request from './index.js'

//获取系统配置信息
export function confInfo(data = {}) {
    return request({
        url: "/api/system/conf",
        method: "GET",
        params: data
    })
}

//修改配置项
export function updateConf(data = {}) {
    return request({
        url: "/api/system/update/base",
        method: "PUT",
        params: data
    })
}


//修改存储配置项
export function updateStorageConf(data = {}) {
    return request({
        url: "/api/system/update/storage",
        method: "PUT",
        params: data
    })
}

//读取fs状态
export function checkFsStatus(data = {}) {
    return request({
        url: "/api/system/fs/status",
        method: "GET",
        params: data
    })
}

//获取配置
export function getConf(data={}) {
    return request({
        url: "/api/system/base",
        method: "GET",
        params: data
    })
}

//获取存储配置
export function getStorage(data={}) {
    return request({
        url: "/api/system/storage",
        method: "GET",
        params: data
    })
}

//获取锁定设备列表
export function getLock(data={}) {
    return request({
        url: "/api/system/lock/search",
        method: "GET",
        params: data
    })
}

//获取锁定设备详情
export function getLockInfo(data={}) {
    return request({
        url: "/api/system/lock/info",
        method: "GET",
        params: data
    })
}

//删除锁定设备地址
export function delLock(data={}) {
    return request({
        url: "/api/system/lock/delete",
        method: "DELETE",
        params: data
    })
}

//清空锁定设备地址
export function clearLock(data={}) {
    return request({
        url: "/api/system/lock/clear",
        method: "DELETE",
        params: data
    })
}

//上传配置文件
export function confImp(data = {}) {
    return request({
        url: "/api/system/conf",
        method: "POST",
        headers: {
            "Content-Type": "multipart/form-data;charset=UTF-8"
        },
        data
    })
}
