<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('新增任务')" v-model="isShowDialog" @close="initForm" width="60%">
      <el-divider></el-divider>
      <el-form v-if="isShowDialog" :model="ruleForm" size="small" label-width="130px" label-position="top"
               ref="validateFrom"
               :rules="rules">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item :label="$t('任务类型')" prop="type">
              <el-select v-model="ruleForm.type" :placeholder="$t('请选择任务类型')" filterable allow-create>
                <el-option :label="$t('话单保存时间生效')" value="1"/>
                <el-option :label="$t('录音保存时间生效')" value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="" prop="time">
              <el-tooltip class="box-item" effect="dark" placement="bottom">
                <span>{{ $t('执行时间') }}
                  <el-icon size="small" color="#409eff">
                    <info-filled/>
                  </el-icon>
                </span>
                <template #content>
                  1、{{ $t('限制为当月内')}} <br/>
                  2、{{ $t('至少为当前时间的10分钟以后')}}
                </template>
              </el-tooltip>
              <el-date-picker v-model="ruleForm.time" type="datetime"
                              :editable="false" :clearable="false"
                              format="YYYY-MM-DD HH:mm" value-format="YYYY-MM-DD HH:mm"
                              @calendar-change="calendar"
                              :disabled-date="disableddate" @change="changeOncick"
                              style="width:100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('备注')">
              <el-input v-model="ruleForm.remark" :rows="3" maxlength="64" type="textarea"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
					<el-button @click="onCancel" size="small">{{ $t('取消') }}</el-button>
					<el-button type="primary" @click="onSubmit" size="small">{{ $t('保存') }}</el-button>
				</span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  ref, computed, onMounted
} from 'vue';
import {useI18n} from "vue-i18n";
import {
  ElNotification
} from 'element-plus';
import {taskAdd} from "@/http/task.api";

export default {
  name: 'trunkAddDod',
  components: {},
  emits: ["print"],
  setup(props, context) {
    const {t}   = useI18n()
    const state = reactive({
      isShowDialog: false,
      id: '',
      ruleForm: {
        type: '',
        time: '',
        remark: '',
      },
      stations: [],
    });
    onMounted(() => {
      let strDate = new Date();
      strDate.setMinutes(strDate.getMinutes() + 15);
      var startTime       = strDate.getFullYear() + "-" + ((strDate.getMonth() + 1) < 10 ? "0" + (strDate.getMonth() +
          1) : (strDate.getMonth() + 1)) + "-" + (strDate.getDate() < 10 ? "0" + strDate.getDate() : strDate.getDate()) + " " + (strDate.getHours() < 10 ? "0" + strDate.getHours() : strDate.getHours()) + ":" + (
          strDate.getMinutes() < 10 ? "0" + strDate.getMinutes() : strDate.getMinutes()) + ":" + (strDate.getSeconds() < 10 ? "0" + strDate.getSeconds() : strDate.getSeconds());
      state.ruleForm.time = startTime;
    })
    const validateFrom = ref()
    //校验规则
    const rules        = computed(() => {
      const rule = {
        type: [{
          required: true,
          message: t("请选择任务类型"),
          trigger: "change"
        }],
        time: [{
          required: true,
          message: t("请定义任务时间"),
          trigger: "blur"
        }],
      }
      return rule;
    })
    // 打开弹窗
    const openDialog   = () => {
      state.isShowDialog = true;
    };
    // 关闭弹窗
    const closeDialog  = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel     = () => {
      closeDialog();
      initForm();
    };

    // 新增
    const onSubmit = () => {
      validateFrom.value.validate((valid) => {
        if (valid) {
          const params = {
            type: state.ruleForm.type,
            time: state.ruleForm.time,
            remark: state.ruleForm.remark,
          };
          taskAdd(params).then((res) => {
            if (res.code == 200) {
              ElNotification({
                title: t("成功"),
                message: t("添加成功"),
                type: 'success',
                duration: 2000,
              });
              validateFrom.value.resetFields();
              state.isShowDialog = false;
              context.emit("print");
            } else {
              ElNotification({
                title: t('失败'),
                message: res.msg,
                type: 'error',
                duration: 2000,
              });
            }
          })
        }
      });
    };

    const initForm = () => {
      let strDate = new Date();
      strDate.setMinutes(strDate.getMinutes() + 15);
      var startTime       = strDate.getFullYear() + "-" + ((strDate.getMonth() + 1) < 10 ? "0" + (strDate.getMonth() +
          1) : (strDate.getMonth() + 1)) + "-" + (strDate.getDate() < 10 ? "0" + strDate.getDate() : strDate.getDate()) + " " + (strDate.getHours() < 10 ? "0" + strDate.getHours() : strDate.getHours()) + ":" + (
          strDate.getMinutes() < 10 ? "0" + strDate.getMinutes() : strDate.getMinutes()) + ":" + (strDate.getSeconds() < 10 ? "0" + strDate.getSeconds() : strDate.getSeconds());
      state.ruleForm = {
        type: '',
        time: startTime,
        remark: '',
      };
    };

    return {
      openDialog,
      closeDialog,
      onCancel,
      onSubmit,
      initForm,
      ...toRefs(state),
      validateFrom,
      rules,
    };
  }
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.card-header h3 {
  font-size: 15px;
}
</style>
