import request from './index.js'

export function taskList(data = {}) {
    return request({
        url: "/api/task/search",
        method: "GET",
        params: data
    })
}

export function timeConf(data = {}) {
    return request({
        url: "/api/system/storage/time",
        method: "GET",
        params: data
    })
}

export function taskInfo(data = {}) {
    return request({
        url: "/api/task/info",
        method: "GET",
        params: data
    })
}

export function taskAdd(data = {}) {
    return request({
        url: "/api/task/add",
        method: "POST",
        params: data
    })
}

export function taskDel(data = {}) {
    return request({
        url: "/api/task/delete",
        method: "DELETE",
        params: data
    })
}
