<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('任务清单')" v-model="isShowDialog" width="70%">
      <el-divider></el-divider>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div style="display: flex;height:30px;">
            <span style="color:#acacac;font-weight: 600;">{{ $t('当前规则') }}:</span>
            <div style="display:flex;flex:1;justify-content: space-around;">
              <span style="">{{ $t('话单保存时间') }}：<span>{{ timeConf.cdrTime }}{{ $t('个月') }}</span></span>
              <span>{{ $t('录音保存时间') }}：<span>{{ timeConf.recordTime }}{{ $t('个月') }}</span></span>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-card shadow="never" class="mb10 u-el-button-small">
            <template #header>
              <div style="display: flex;">
                <el-button type="primary" size="small" @click="onOpenAddTask">
                  <el-icon :size="16" style="vertical-align: middle;margin-right:3px;">
                    <CirclePlus/>
                  </el-icon>
                  <span style="vertical-align: middle">{{ $t('添加') }}</span>
                </el-button>
              </div>
            </template>
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-table :data="data" stripe style="width: 100%" class="table" id="out-table">
                  <el-table-column prop="type" :label="$t('任务类型')"
                                   show-overflow-tooltip width="220">
                    <template #default="scope">
                      <el-tag size="medium" type="primary" effect="plain" v-if="scope.row.type == 1">
                        {{ $t('话单保存时间生效') }}
                      </el-tag>
                      <el-tag size="medium" type="warning" effect="plain" v-if="scope.row.type == 2">
                        {{ $t('录音保存时间生效') }}
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" :label="$t('任务状态')"
                                   show-overflow-tooltip>
                    <template #default="scope">
                      <el-tag size="medium" type="success" v-if="scope.row.status == 1">{{ $t('已执行') }}</el-tag>
                      <el-tag size="medium" type="info" v-if="scope.row.status == 0">{{ $t('未执行') }}</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="handle_time" :label="$t('执行时间')"
                                   show-overflow-tooltip width="180"></el-table-column>
                  <el-table-column prop="remark" :label="$t('备注')"
                                   show-overflow-tooltip></el-table-column>
                  <el-table-column prop="path" :label="$t('操作')" width="90" fixed="right">
                    <template #default="scope">
                      <el-button type="text" @click="delSub(scope.row)" :title="$t('删除')"
                                 style="margin-left: 0px !important;">
                        <el-icon :size="16" color="#f63d3d">
                          <Delete/>
                        </el-icon>
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination v-if="clientWidth >= 1000" @size-change="onHandleSizeChange"
                               @current-change="onHandleCurrentChange" class="mt15" :pager-count="5"
                               :page-count="pageCount"
                               :current-page="pageNum" background :page-size="pageSize"
                               layout="total, prev, pager, next, jumper"
                               :total="total">
                </el-pagination>
                <el-pagination v-else @size-change="onHandleSizeChange" @current-change="onHandleCurrentChange"
                               :page-size="pageSize" small layout=" prev, pager, next"
                               :page-count="pageCount"
                               :current-page="pageNum"
                               :total="total" class="mt15"></el-pagination>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
      <addTask ref="addTaskRef" @print="refTaskList"/>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive, ref,
  toRefs,
} from 'vue';
import {useI18n} from "vue-i18n";
import {taskList, taskDel, timeConf} from "@/http/task.api";
import {
  ElMessageBox,
  ElNotification
} from 'element-plus';
import {CirclePlus, Delete} from "@element-plus/icons-vue";
import addTask from "./taskAdd.vue";

function timeConfData(state) {
  timeConf({}).then(res => {
    state.timeConf.cdrTime    = res.data.cdrTime;
    state.timeConf.recordTime = res.data.recordTime;
  }).catch(err => {
    console.log(err, 'err');
  })
}

export default {
  name: 'taskData',
  components: {addTask, CirclePlus, Delete},
  emits: ["print"],
  setup() {
    const {t}      = useI18n()
    const state    = reactive({
      isShowDialog: false,
      keyword: '',
      activeName: 'first',
      tabPosition: 'left',
      timeConf: {
        cdrTime: '',
        recordTime: '',
      },
      selectionList: [],
      data: [],
      pageSize: 5,  //每页数据条数
      pageNum: 1,   //页码
      total: 0,     //总数据条数
      pageCount: 0, //总页数
      sortType: '',
      sortBy: '',
      clientWidth: '',
    });
    const dataList = () => {
      let params = {
        keyword: state.keyword,
        page: state.pageNum,
        sort_type: state.sortType,
        sort_by: state.sortBy
      }
      taskList(params).then(res => {
        if (res.code == 200) {
          state.data      = res.data.list;
          state.pageCount = res.data.pages;
          state.pageSize  = res.data.records;
          state.total     = res.data.rows;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }

    // 打开弹窗
    const openDialog = () => {
      dataList();
      timeConfData(state);
      state.isShowDialog = true;
    };

    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };

    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm = () => {
      state.ruleForm.name   = '';
      state.ruleForm.phone  = '';
      state.ruleForm.record = '';
      state.ruleForm.from   = '';
    };

    const selectionChange = (list) => {
      state.selectionList = list.map(item => {
        return item.id
      });
    }
    const changeSort      = (column) => {
      state.sortBy   = column.prop;
      state.sortType = column.order;
      dataList()
    }

    // 分页改变
    const onHandleCurrentChange = (val) => {
      state.pageNum = val;
      dataList();
    };

    // 分页改变
    const onHandleSizeChange = (val) => {
      state.pageSize = val;
      dataList();
    };

    const delSub = (row) => {
      ElMessageBox.confirm(t("你确定要删除该数据吗"), t("提示"), {
        confirmButtonText: t("确定"),
        cancelButtonText: t("取消"),
        type: "warning",
        callback: (action) => {
          if (action === "confirm") {
            let params = {
              id: row.id
            }
            taskDel(params).then(res => {
              if (res.code == 200) {
                ElNotification({
                  title: t("成功"),
                  message: t("删除成功"),
                  type: "success",
                  duration: 2000,
                });
                dataList()
              } else {
                ElNotification({
                  title: t('失败'),
                  message: res.msg,
                  type: 'error',
                  duration: 2000,
                });
              }
            })
          }
        }
      })
    }

    const addTaskRef    = ref();
    const onOpenAddTask = (row) => {
      addTaskRef.value.openDialog(row);
    };

    const refTaskList = () => {
      dataList();
    }

    return {
      openDialog,
      closeDialog,
      onCancel,
      ...toRefs(state),
      addTaskRef,
      onOpenAddTask,
      delSub,
      refTaskList,
      selectionChange,
      changeSort,
      onHandleCurrentChange,
      onHandleSizeChange,
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
  margin-bottom: 10px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.input-number-range {
  background-color: #fff;
  //border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.flex {
  display: flex;
  padding: 0 !important;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .center {
    margin-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.item .el-input__suffix {
  top: -7px;
}

.item .el-input__icon {
  height: 24px !important;
  line-height: 24px !important;
}

.u-fa-icon {
  margin-right: 3px;
}
</style>
