<template>
  <div class="system-menu-container">
    <el-dialog :title="$t('锁定地址明细')" v-model="isShowDialog" width="60%">
      <el-divider></el-divider>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-card shadow="never" class="mb10 u-el-button-small">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-table :data="data" stripe style="width: 100%" class="table" id="out-table">
                  <el-table-column prop="dn" :label="$t('号码')"
                                   show-overflow-tooltip></el-table-column>
                  <el-table-column prop="mac" label="Mac"
                                   show-overflow-tooltip></el-table-column>
                  <el-table-column prop="agent" label="Agent"
                                   show-overflow-tooltip></el-table-column>
                  <el-table-column prop="create_time" :label="$t('时间')"
                                   show-overflow-tooltip width="180"></el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-card>
        </el-col>

      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
} from 'vue';
import {useI18n} from "vue-i18n";
import {getLockInfo} from "@/http/system.api";
import {
  ElNotification
} from 'element-plus';

export default {
  name: 'lockInfo',
  components: {},
  emits: ["print"],
  setup() {
    const {t}      = useI18n()
    const state    = reactive({
      isShowDialog: false,
      addr: '',
      selectionList: [],
      data: [],
      clientWidth: '',
    });
    const lockInfo = () => {
      let params = {
        addr: state.addr,
      }
      getLockInfo(params).then(res => {
        if (res.code == 200) {
          state.data = res.data;
        } else {
          ElNotification({
            title: t('失败'),
            message: res.msg,
            type: 'error',
            duration: 2000,
          });
        }
      }).catch(err => {
        console.log(err, 'err');
      })
    }

    // 打开弹窗
    const openDialog = (row) => {
      state.addr = row.address;
      lockInfo();
      state.isShowDialog = true;
    };

    // 关闭弹窗
    const closeDialog = () => {
      state.isShowDialog = false;
    };
    // 取消
    const onCancel    = () => {
      closeDialog();
      initForm();
    };

    // 表单初始化，方法：`resetFields()` 无法使用
    const initForm = () => {
      state.data = [];
    };

    return {
      openDialog,
      closeDialog,
      onCancel,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss">
.el-dialog__body {
  padding-top: 0;
}

.column {
  flex-direction: column;
}

.el-divider--horizontal {
  margin-top: 15px;
}

.el-button--primary.is-disabled {
  display: block;
}

.el-button [class*=el-icon-] + span {
  margin-left: 0;
}

.el-transfer__buttons {
  padding: 0 10px;
}

.member {
  font-size: 1rem;
  margin-left: 25px;
  margin-bottom: 5px;
}

.el-transfer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-transfer {
  --el-transfer-panel-width: 40%;
  margin-bottom: 35px;
}

.footer {
  audio {
    margin-top: 5px;
    width: 100%;
  }
}

.el-form-item__content {
  margin-left: 0 !important;
}

.card-header {
  display: flex;
  justify-content: space-between;
}

.item {
  width: 100px;

  /* 调整elementUI中样式 如果不需要调整请忽略 */
  .el-input__inner {
    height: 24px;
  }
}

.txt {
  line-height: 24px;
  padding: 0 9px;
  box-sizing: border-box;
}

.input-number-range {
  background-color: #fff;
  //border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.flex {
  display: flex;
  padding: 0 !important;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .center {
    margin-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.item .el-input__suffix {
  top: -7px;
}

.item .el-input__icon {
  height: 24px !important;
  line-height: 24px !important;
}

.u-fa-icon {
  margin-right: 3px;
}
</style>
